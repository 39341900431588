import { LayoutComponent } from "./components/layout.component";

import { AppConfig } from "./app.config";

import { OveraModule } from "./modules/overa.module";
import { PrimeNgModule } from "./modules/primeng.module";
import { VendorModule } from "./modules/vendor.module";
import { TestComponent } from "./components/test/test.component";
import { APP_BASE_HREF, CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  InitModule,
  OveraAppComponent,
  OveraAppRoutingModule,
} from "@overa/base";
import {
  NgxTranslateService,
  OveraTranslateModule,
  UtcDateInterceptor,
} from "@overa/shared";
import { ServiceWorkerModule } from "@angular/service-worker";
import { ToastrModule } from "ngx-toastr";
import { MenuSettings } from "./menusettings";
import { OAuthModule } from "angular-oauth2-oidc";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, isDevMode } from "@angular/core";
import {
  AuthInterceptor,
  ErrorHandlerInterceptor,
  TenantInterceptor,
  UserInterceptor,
} from "@overa/security";

export function getBaseUrlFactory() {
  try {
    return document.getElementsByTagName("base")[0].href ?? "/";
  } catch {
    return "/";
  }
}

@NgModule({
  declarations: [LayoutComponent, TestComponent],
  imports: [
    InitModule.setConfig(AppConfig),
    CommonModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    OveraModule,
    PrimeNgModule,
    VendorModule,

    OveraAppRoutingModule.forRoot(MenuSettings.MENU.routes ?? []),

    ToastrModule.forRoot({
      positionClass: "toast-bottom-right",
      timeOut: 5000,
      enableHtml: true,
      preventDuplicates: true,
    }),

    OAuthModule.forRoot(),

    OveraTranslateModule.setConfig("es", ["common"]),

    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    // The APP_BASE_HREF provider is defined here in the AppModule to ensure that Angular's routing system
    // correctly recognizes the application's base path for all navigation and routing operations.
    // Defining APP_BASE_HREF at the module level, rather than in the main.ts, guarantees that it is properly
    // utilized by the RouterModule and any lazy-loaded modules, thus avoiding routing issues where Angular
    // might not match routes correctly if the base path is not explicitly set. This approach aligns with best
    // practices for Angular application setup, especially in cases where the application might be served from
    // a subdirectory or require different base paths in different environments.

    { provide: APP_BASE_HREF, useFactory: getBaseUrlFactory },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TenantInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UserInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UtcDateInterceptor, multi: true },
  ],
  bootstrap: [OveraAppComponent],
  entryComponents: [],
})
export class AppModule {
  constructor(private translate: NgxTranslateService) {}
}
