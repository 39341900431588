<div *ngIf="version" class="version-layer">**&nbsp;{{ version }}&nbsp;**</div>
<div class="container-master">
  <div
    *ngIf="!kioskMode"
    class="sidebar"
    data-color="white"
    data-background-color="black"
  >
    <app-sidebar-cmp #sidebar [menuItems]="menuItems"></app-sidebar-cmp>
  </div>

  <div *ngIf="!kioskMode" class="main-navbar">
    <section *ngIf="!kioskMode" class="top-nav-responsive">
      <label class="menu_responsive-button-container" for="menu_responsive-toggle">
        <div class="menu_responsive-button"></div>
      </label>
      <ul class="menu_responsive"></ul>
    </section>

    <overa-navbar
      #navbar
      [menuItems]="menuItems"
      [menubarItems]="menubarItems"></overa-navbar>
  </div>

  <div class="container-level-2">
    <div class="root-layout">
      <ngx-spinner
        bdColor="rgba(0, 0, 0, 0.4)"
        size="default"
        color="#fff"
        type="ball-newton-cradle"
      >
        <p class="spinner-text-color" i18n="@@messages.loading">Cargando</p>
      </ngx-spinner>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
