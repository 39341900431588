import {
  AuthGuard,
  DefaultRedirectGuard,
  MenuDefinition,
  PermissionsGuard,
} from "@overa/base";

import { LayoutComponent } from "./components/layout.component";

import { TestComponent } from "./components/test/test.component";

export class MenuSettings {
  public static MENU: MenuDefinition = {
    routes: [
      {
        path: "",
        pathMatch: "prefix",
        component: LayoutComponent,
        canActivate: [DefaultRedirectGuard],
        canActivateChild: [AuthGuard],

        children: [
          {
            path: "test",
            component: TestComponent,
          },

          {
            path: "core",
            loadChildren: () =>
              import(
                /* webpackChunkName: "menusettings-overa-base" */
                "@overa/base"
              ).then((m) => m.LibRoutingModule),
          },
          {
            path: "m",
            loadChildren: () =>
              import(
                /* webpackChunkName: "menusettings-maintenances" */
                "./components/maintenances/maintenance.module"
              ).then((m) => m.MaintenanceModule),
          },
          {
            path: "message-templates",
            data: {
              breadcrumb: null,
            },
            canActivate: [PermissionsGuard],

            pathMatch: "prefix",
            loadChildren: () =>
              import(
                /* webpackChunkName: "menusettings-overa-notification" */
                `@overa/notification`
              ).then((m) => m.MessageTemplateRoutingModule),
          },
          {
            path: "m",
            data: {
              breadcrumb: null,
            },
            canActivate: [PermissionsGuard],

            pathMatch: "prefix",
            loadChildren: () =>
              import(
                /* webpackChunkName: "menusettings-overa-dynamic-form" */
                `@overa/dynamic/components`
              ).then((m) => m.OveraDynamicFormRoutingModule),
          },

          {
            path: "d",
            loadChildren: () =>
              import(
                /* webpackChunkName: "menusettings-overa-dynamic" */
                "@overa/dynamic/components"
              ).then((m) => m.OveraDynamicRoutingModule),
          },
          {
            path: "de",
            loadChildren: () =>
              import(
                /* webpackChunkName: "menusettings-overa-dynamic" */
                "@overa/dynamic/components"
              ).then((m) => m.OveraDynamicMaintenancesRoutingModule),
          },
        ],
      },
      {
        path: "security",
        pathMatch: "prefix",
        loadChildren: () =>
          import(
            /* webpackChunkName: "menusettings-overa-security" */
            "@overa/security"
          ).then((m) => m.SecurityRoutingModule),
      },
    ],
  };
}
