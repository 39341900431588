import { NgModule } from "@angular/core";
import { NgPipesModule } from "ngx-pipes";
import { NgxSpinnerModule } from "ngx-spinner";

export function ngxSpinnerConfig() {
  return {
    type: "line-scale-pulse-out",
    size: "default",
    color: "#e1e1e2",
  };
}

@NgModule({
  imports: [
    NgxSpinnerModule,
    NgPipesModule,
  ],
})
export class VendorModule {}
