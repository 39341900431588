import { NgModule } from "@angular/core";

import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { MenubarModule } from "primeng/menubar";
import { ContextMenuModule } from "primeng/contextmenu";
import { ConfirmDialogModule } from "primeng/confirmdialog";

import { MenuModule } from "primeng/menu";

@NgModule({
  imports: [
    MenubarModule,
    ContextMenuModule,
    ButtonModule,
    InputTextModule,
    ConfirmDialogModule,
    MenuModule,
    ContextMenuModule,
    ButtonModule,
    InputTextModule,
  ],
  providers: [
  ],
  exports: [
    MenubarModule,
    ContextMenuModule,
    ButtonModule,
    InputTextModule,
    ConfirmDialogModule,
    MenuModule,
    ContextMenuModule,
    ButtonModule,
    InputTextModule,
  ],
})
export class PrimeNgModule {}
