import { environment } from "src/environments/environment";
import { dynamic_registry } from "./utils/dynamic_registry";

/**
 * Represents the application configuration.
 */
export const AppConfig = {
  /**
   * The name of the application.
   */
  appName: environment.app,

  /**
   * The version of the application.
   */
  appVersion: environment.version,

  /**
   * Custom configuration function that modifies the config data based on app settings.
   * @param configData - The configuration data object.
   * @param appSettingsData - The application settings data.
   */
  customConfig: (configData: { [key: string]: any }, appSettingsData: any) => {
    const secureApis = appSettingsData.secureApis?.split(",");
    const key = appSettingsData.encryptKey;

    configData["overa-security"].kv = {};
    if (secureApis) {
      secureApis.forEach((a: string) => {
        configData["overa-security"].kv[a] = key;
      });
    }

    configData["overa-components"]["overa-error-dialog"].showToast =
      environment.production;

    const parentRoute = "d";
    const dynamicViewListRoute = "";
    const dynamicFormParentRoute = "/m/dynamicForm";
    configData["overa-dynamic"].dynamicEntityParentRoute = parentRoute;
    configData["overa-dynamic"].dynamicListParentRoute = dynamicViewListRoute;
    configData["overa-dynamic"].dynamicFormParentRoute = dynamicFormParentRoute;

    configData["overa-dynamic"]["registry"] = dynamic_registry;

    configData["overa-dynamic"].configApisDynamic = [
      ...configData["overa-dynamic"].configApisDynamic,
      {
        name: "commonApi",
        url: appSettingsData.commonApiUrl
          ? `${appSettingsData.commonApiUrl}`
          : "",
      },
    ];
  },
};
