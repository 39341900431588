import {
  Component,
} from "@angular/core";

import { OveraLayoutComponent } from "@overa/base";

import { environment } from "src/environments/environment";

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
})
export class LayoutComponent extends OveraLayoutComponent {
  static override className = "LayoutComponent";
  
  version!: string;

  override init = () => {
    if (!environment.production || environment.version.endsWith("-stg")) {
      this.version = environment.version;
    } else {
      this.version = "?";
      console.log(`${environment.app} ${environment.version}`);
    }
  };
}
