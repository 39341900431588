import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import {
  ComponentLoaderModule,
  FixedpluginModule,
  FooterModule,
  IconsModule,
  OveraBaseModule,
  SidebarModule,
} from "@overa/base";

import {
  OveraNotificationModule,
  PushMiddlewareService,
} from "@overa/notification";
import { SecurityModule } from "@overa/security";
import {
  ConfigsService,
  CustomValidators,
  DynamicPipeModule,
  MaterialModule,
  OveraEllipsisModule,
  OveraUtils,
  SafePipeModule,
} from "@overa/shared";
import {
  FileuploadModule,
  FileuploadMultipleModule,
  StorageService,
} from "@overa/storage";
import { OveraNavbarModule } from "@overa/components/navbar";


@NgModule({
  imports: [
    FileuploadModule,
    FileuploadMultipleModule,
    OveraEllipsisModule,
    OveraNotificationModule,
    OveraBaseModule,
    OveraNavbarModule,
    SecurityModule,
    ComponentLoaderModule,
    FixedpluginModule,
    IconsModule,
    FooterModule,
    SidebarModule,
    MaterialModule,
    SafePipeModule,
    DynamicPipeModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    StorageService,
    PushMiddlewareService,
    OveraUtils,
    ConfigsService,
    CustomValidators,
  ],
  exports: [
    FileuploadModule,
    FileuploadMultipleModule,
    OveraEllipsisModule,
    OveraNotificationModule,
    OveraBaseModule,
    OveraNavbarModule,
    SecurityModule,
    ComponentLoaderModule,
    FixedpluginModule,
    IconsModule,
    FooterModule,
    SidebarModule,
    MaterialModule,
    DynamicPipeModule,
  ],
})
export class OveraModule {}
