import { Injectable } from "@angular/core";

import { Test } from "../models/test.model";
import { BaseService } from "@overa/shared";

@Injectable({ providedIn: "root" })
export class TestService extends BaseService<Test> {

  override get path(): string {
    return "test";
  }

  override get apiBaseUrl() {
    return this.config.getModuleConfig("overa-security", "apiBaseUrl") + "/api";
  }

}
